import React from 'react'
import profil from '../images/about/profil.jpg'
import softwares from '../images/about/softwares.jpg'
import development from '../images/about/development.jpg'
import Layout from '../components/layout'

const AboutPage = () => (
  <Layout>
    <h1 className="main-title text-center">
      About
    </h1>
    <div className="profile">
      <div className="profile-picture">
        <div className="img-holder">
          <img src={profil} alt="Florian Wautelet" />
        </div>
      </div>
      <div className="profile-information">
        <h2 className="about-sub-title">
          Profile
        </h2>
        <ul className="about-list">  
          <li>Able to work under stress and respect deadlines.</li>
          <li>Working on projects alone of within a team suit me.</li>
          <li>Able to adapt to new environments.</li>
          <li>Capable to watch and learn quickly.</li>
        </ul>
        <h2 className="about-sub-title job-title">
          Jobs
        </h2>
        <ul className="about-list">  
          <li>Set surfacing artist</li>
          <li>Technical developer</li>
        </ul>
        <h2 className="about-sub-title job-title">
          Infographic 2D/3D
        </h2>
        <div className="img-holder img-holder-left">
          <img src={softwares} alt="Softwares" height="50" className="about-image" />
        </div>
        <h2 className="about-sub-title job-title">
          Development
        </h2>
        <div className="img-holder img-holder-left">
          <img src={development} alt="Development" height="50" className="about-image" />
        </div>
        <h2 className="about-sub-title job-title">
          Degree
        </h2>
        <p className="about-text no-margin bold margin-left-20">
          Master in Technical Graphics Communications option 2D-3D
        </p>
        <p className="about-text no-margin margin-left-20">
          at l'institut des Arts de Diffusion (Belgian School) September 2008 to December 2013.
        </p>
      </div>
    </div>
  </Layout>
)

export default AboutPage